import Vue from 'vue'
import getUserLocale from 'get-user-locale'
import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/relativeTime'))

let locale = getUserLocale()

const shortDate = new Intl.DateTimeFormat('en-GB', {
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric'
})

const longDate = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short'
})

const day = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
})

Vue.filter('favicon', (url) => {
  if (!url) {
    return null
  }

  return `https://www.google.com/s2/favicons?sz=32&domain=${url}`
})

Vue.filter('shortDate', timestamp => {
  return shortDate.format(new Date(timestamp * 1000))
})

Vue.filter('longDate', timestamp => {
  return longDate.format(new Date(timestamp * 1000))
})

Vue.filter('day', timestamp => {
  return day.format(new Date(timestamp * 1000))
})

Vue.filter('fromNow', timestamp => {
  return dayjs.unix(timestamp).fromNow()
})

Vue.filter('percentage', percentage => {
  return (percentage * 100).toFixed(2) + '%'
})

Vue.filter('uptime', uptime => {
  if (uptime === null) return ''

  const floored = Math.floor(uptime * 10000) / 100
  return floored.toFixed(2) + '%'
})

Vue.filter('uptimeWholeNumber', uptime => {
  if (uptime === null) {
    return ''
  }

  return (uptime * 100).toFixed(0) + '%'
})

Vue.filter('responseTime', responseTime => {
  if (responseTime === null) {
    return ''
  }

  return responseTime.toFixed(2)
})

Vue.filter('monitorStatus', (status) => {
  switch (status) {
    case 'online': return 'Online'
    case 'offline': return 'Offline'
    default: return 'Checking...'
  }
})

Vue.filter('monitorNameOrUrl', monitor => {
  if (monitor.name) {
    return monitor.name
  }

  if (validateIPAddress(monitor.url)) {
    return monitor.url
  }

  let a = document.createElement('a')
  a.href = monitor.url

  // Check if the monitor url contains protocol. If not, add protocol and get the hostname
  if (!/^https?:\/\//i.test(monitor.url)) {
    const url = 'https://' + monitor.url
    return new URL(url).hostname
  }
  return a.hostname
})

export function validateIPAddress (ipaddress) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)
}

Vue.filter('sslCertificateState', state => {
  switch (state) {
    case 'not_found': return 'Not Found'
    case 'valid': return 'Healthy'
    case 'invalid': return 'Expired'
    case 'off': return 'Not Checking'
    default: return 'Checking...'
  }
})

Vue.filter('domain', url => {
  let a = document.createElement('a')
  a.href = url

  return a.hostname
})

Vue.filter('withoutProtocol', url => {
  return url.replace(/(^\w+:|^)\/\//, '')
})

Vue.filter('truncate', (string, length) => {
  if (string.length > length) {
    return string.substring(0, length) + '...'
  }

  return string
})

Vue.filter('uppercase', string => {
  return string.toUpperCase()
})

Vue.filter('requestBodyType', type => {
  switch (type) {
    case 'raw': return 'Raw'
    case 'json': return 'Json'
    case 'form_params': return 'Form Params'
  }

  return null
})

Vue.filter('responseSize', (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

Vue.filter('notificationType', type => {
  switch (type) {
    case 'MonitorOnline': return 'Monitor Online'
    case 'MonitorOffline': return 'Monitor Offline'
    case 'SSLCertificateExpired': return 'SSL Certificate Expired'
    case 'SSLCertificateExpiresSoon': return 'SSL Certificate Expires Soon'
    case 'SSLCertificateDownloadFailed': return 'SSL Certificate Download Failed'
    case 'MonitorOfflinePaused': return 'Offline Monitor Paused'
    case 'InactiveUserMonitorsPaused': return 'Monitors Paused'
  }
})

Vue.filter('incidentStatus', state => {
  switch (state) {
    case 'exploring': return 'Exploring'
    case 'identified': return 'Identified'
    case 'update': return 'Update'
    case 'resolved': return 'Resolved'
  }
})

Vue.filter('costs', costs => {
  let formattedCosts = Number(costs).toFixed(4)

  return new Intl.NumberFormat(locale, { style: 'currency', currency: 'USD' }).format(formattedCosts)
})

Vue.filter('number', value => {
  return new Intl.NumberFormat(locale).format(value)
})

Vue.filter('uptimeWithoutRounding', (uptime, decimals = 2) => {
  if (uptime === null) {
    return ''
  }
  uptime = uptime * 100
  const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?') // eslint-disable-line
  return uptime.toString().match(re)[0] + '%'
})
